@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* added this override to provide rounded border to datetime picker */
  .react-datetime-picker__wrapper {
    border: none;
    border-radius: 6px;
  }
}

@layer utilities {
  .parent-for-copy * {
    max-width: 100%;
    background-color: #ccc;
    /* background-color: transparent; */
    position: relative;
  }

  .text-wrap-all * {
    text-wrap: wrap;
  }

  .info-markdown * {
    font-size: 1.125rem /* 20px */;
    line-height: 1.5rem /* 28px */;
    margin-top: 2rem; /* 32px */
  }

  .info-markdown h3 * {
    font-size: 1.25rem /* 20px */;
    line-height: 1.75rem /* 28px */;
  }

  .chatbot-response * {
    max-width: 100%;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
